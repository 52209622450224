import { getSSCID } from '@rei/sscid-listener';
import { PROD_DEFAULT } from './app-config';

export const getEnv = (envConfig) => {
  let env;
  if (envConfig && (envConfig === 'qa')) {
    env = 'future';
  } else {
    const domain = document.location.hostname;
    env = PROD_DEFAULT.some((serv) => domain.includes(serv))
      ? 'www' : 'future';
  }
  return env;
};

export const gpcListener = async () => {
  let gpcValue = navigator.globalPrivacyControl || false;
  const sscid = getSSCID();

  if (gpcValue && sscid) {
    const endpointUrl = `https://${getEnv(window.gpcEnvironment)}.rei.com/gpc/signals/ciam`;
    const response = await fetch(endpointUrl, {
      method: 'POST',
      priority: 'low',
      body: JSON.stringify({ sscid }),
    });
    if (!response.ok) {
      gpcValue = false;
    }
  }
  return gpcValue;
};

export default {
  getEnv,
};
